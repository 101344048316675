import React from 'react'
import styled from 'styled-components'
import { PrimaryButton } from '../HeroSection/HeroStyle';
import { Bio } from '../../data/constants';

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
position: relative;
z-index: 1;
align-items: center;
@media (max-width: 960px) {
    padding: 0px;
}
`

const Wrapper = styled.div`
position: relative;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
width: 100%;
padding-top: 80px;
padding-bottom: 80px;
max-width: 1350px;
gap: 12px;
@media (max-width: 960px) {
    flex-direction: column;
}
`

const Title = styled.div`
font-size: 42px;
text-align: center;
font-weight: 600;
margin-top: 20px;
  color: ${({ theme }) => theme.primary};
  @media (max-width: 768px) {
      margin-top: 12px;
      font-size: 32px;
  }
`;

const Desc = styled.div`
    font-size: 18px;
    text-align: center;
    max-width: 600px;
    padding: 0 20px;
    color: ${({ theme }) => theme.text_secondary};
    @media (max-width: 768px) {
        margin-top: 12px;
        font-size: 16px;
    }
`;



const Contact = () => {

  return (
    <Container id="contact">
      <Wrapper>
        <Title>Contact</Title>
        <Desc>Want to chat? The best way to reach me is on Linkedin.</Desc>
        <br />
        <PrimaryButton href={Bio.linkedin} target='display'>Get In Touch</PrimaryButton>
      </Wrapper>
    </Container>
  )
}

export default Contact