import React from 'react'
import { useState } from 'react'
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './ProjectsStyle'
import ProjectCard from '../Cards/ProjectCards'
import { projects } from '../../data/constants'


const Projects = ({openModal,setOpenModal}) => {
  const [toggle, setToggle] = useState('all');
  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projects</Title>
        <Desc>
          Below you can find some of my recent projects. I am currently building full stack applications using NextJs, Tailwind, and Supabase.
        </Desc>
        <ToggleButtonGroup >
          {toggle === 'all' ?
            <ToggleButton active value="all" onClick={() => setToggle('all')}>View All</ToggleButton>
            :
            <ToggleButton value="all" onClick={() => setToggle('all')}>View All</ToggleButton>
          }
          <Divider />
          {toggle === 'vanilla-js' ?
            <ToggleButton active value="vanilla-js" onClick={() => setToggle('vanilla-js')}>Vanilla Js</ToggleButton>
            :
            <ToggleButton value="vanilla-js" onClick={() => setToggle('vanilla-js')}>Vanilla Js</ToggleButton>
          }
          <Divider />
          {toggle === 'next-js' ?
            <ToggleButton active value="next-js" onClick={() => setToggle('next-js')}>NextJs</ToggleButton>
            :
            <ToggleButton value="next-js" onClick={() => setToggle('next-js')}>NextJs</ToggleButton>
          }
        </ToggleButtonGroup>
        <CardContainer>
          {toggle === 'all' && projects
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
          {projects
            .filter((item) => item.category === toggle)
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  )
}

export default Projects